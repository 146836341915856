import "./lite-vimeo-embed.css";
import { addPrefetch, getThumbnailDimensions, canUseWebP } from "./utils.js";

/**
 * Ported from https://github.com/paulirish/lite-youtube-embed
 *
 * A lightweight vimeo embed. Still should feel the same to the user, just MUCH faster to initialize and paint.
 *
 * Thx to these as the inspiration
 *   https://storage.googleapis.com/amp-vs-non-amp/youtube-lazy.html
 *   https://autoplay-youtube-player.glitch.me/
 *
 * Once built it, I also found these:
 *   https://github.com/ampproject/amphtml/blob/master/extensions/amp-youtube (👍👍)
 *   https://github.com/Daugilas/lazyYT
 *   https://github.com/vb/lazyframe
 */
class LiteVimeo extends HTMLElement {
    constructor() {
        super();
        this.connectedCallback = this.connectedCallback.bind(this);
        this._getThumbnailURL = this._getThumbnailURL.bind(this);
        this._addIframe = this._addIframe.bind(this);
        this._playing = false;
        this.hasBackgroundAttr = this.hasAttribute("background");
        // TODO: support dynamically setting the attribute via attributeChangedCallback
    }

    async connectedCallback() {
        // Gotta encode the untrusted value
        // https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-2---attribute-escape-before-inserting-untrusted-data-into-html-common-attributes
        this.videoId = encodeURIComponent(this.getAttribute("videoid"));

        const thumbnailUrl = await this._getThumbnailURL();

        this.style.backgroundImage = `url("${thumbnailUrl}")`;

        if (!this.hasAttribute("background")) {
            const playBtn = document.createElement("button");
            playBtn.type = "button";
            playBtn.name = "Play video";
            playBtn["ariaLabel"] = "Play video";
            playBtn.classList.add("ltv-playbtn");
            this.appendChild(playBtn);
        }

        // On hover (or tap), warm up the TCP connections we're (likely) about to use.
        this.addEventListener("pointerover", LiteVimeo._warmConnections, {
            once: true,
        });

        // Once the user clicks, add the real iframe and drop our play button
        // TODO: In the future we could be like amp-youtube and silently swap in the iframe during idle time
        //   We'd want to only do this for in-viewport or near-viewport ones: https://github.com/ampproject/amphtml/pull/5003
        this.addEventListener("click", () => this._addIframe());

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };
        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                // changing opacity of element based on its visibility on the viewport
                if (entry.isIntersecting) {
                    this._addIframe();
                }
                // entry.target.style.opacity = entry.intersectionRatio;
            });
        };
        let observer = new IntersectionObserver(callback, options);
        observer.observe(this);
    }

    async _getThumbnailURL() {
        /**
         * Lo, the vimeo placeholder image!  (aka the thumbnail, poster image, etc)
         * We have to use the Vimeo API.
         */
        let { width, height } = getThumbnailDimensions(
            this.getBoundingClientRect()
        );
        const devicePixelRatio = window.devicePixelRatio || 1;
        width *= devicePixelRatio;
        height *= devicePixelRatio;

        /**
         * Thumbnail fetching and comments below are originally
         * from https://github.com/slightlyoff/lite-vimeo
         */

        // API is the video-id based
        // http://vimeo.com/api/v2/video/364402896.json
        //        const apiUrl = `https://vimeo.com/api/v2/video/${this.videoId}.json`;
        const apiUrl = `https://api.vimeo.com/videos/${this.videoId}/pictures`;

        // Now fetch the JSON that locates our placeholder from vimeo's JSON API
        const apiResponse = await (
            await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer 0b326f7c9dee9f126e8b12fcf6c3b85e`,
                },
            })
        ).json();

        // console.log('apiResponse', apiResponse);
        const thumbnail = `${apiResponse.data[0].base_link}_${width}x${height}?r=pad`;
        // Extract the image id, e.g. 819916979, from a URL like:
        // thumbnail_large: "https://i.vimeocdn.com/video/819916979_640.jpg"
        // const tnLarge = apiResponse.thumbnail_large;
        // const imgId = (tnLarge.substr(tnLarge.lastIndexOf("/") + 1)).split("_")[0];

        // let thumbnailUrl = `https://i.vimeocdn.com/video/${imgId}`;
        // thumbnailUrl += `.${canUseWebP() ? 'webp' : 'jpg'}`;
        // thumbnailUrl += `?mw=${width}&mh=${height}&q=${devicePixelRatio > 1 ? 70 : 85}`;

        // return thumbnailUrl;
        // console.log('thumbnail', thumbnail);
        return thumbnail;
    }

    // // TODO: Support the the user changing the [videoid] attribute
    // attributeChangedCallback() {
    // }

    /**
     * Begin pre-connecting to warm up the iframe load
     * Since the embed's network requests load within its iframe,
     *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
     * So, the best we can do is warm up a few connections to origins that are in the critical path.
     *
     * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
     * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
     */
    static _warmConnections() {
        if (LiteVimeo.preconnected) return;

        // The iframe document and most of its subresources come right off player.vimeo.com
        addPrefetch("preconnect", "https://player.vimeo.com");
        // Images
        addPrefetch("preconnect", "https://i.vimeocdn.com");
        // Files .js, .css
        addPrefetch("preconnect", "https://f.vimeocdn.com");
        // Metrics
        addPrefetch("preconnect", "https://fresnel.vimeocdn.com");

        LiteVimeo.preconnected = true;
    }

    _addIframe() {
        const background = this.hasAttribute("background");
        // console.log("background =>", this, background);
        // let options = background
        //     ? `autoplay=1&loop=1&autopause=0&title=0&sidedock=0&controls=0`
        //     : `autoplay=1`;
        let options = background ? `background=1&controls=0` : `autoplay=1`;
        //         const iframeHTML = `
        // <iframe width="640" height="360" frameborder="0"
        //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        //   src="https://player.vimeo.com/video/${this.videoId}?autoplay=1"
        // ></iframe>`;
        const alreadyAdded = !!this.querySelector("iframe");
        const iframeHTML = `
<iframe width="640" height="360" frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
  src="https://player.vimeo.com/video/${this.videoId}?${options}"
>
</iframe>`;
        if (!alreadyAdded && !this._playing) {
            // console.log("activating", this.videoId);
            this.insertAdjacentHTML("beforeend", iframeHTML);
            this.classList.add("ltv-activated");
        }
    }
}
// Register custom element
customElements.define("lite-vimeo", LiteVimeo);
